import React from "react";
import Webcam from "react-webcam";
import _ from "lodash";
import { Responsive, WidthProvider, CompactType, Layout as GridLayout } from 'react-grid-layout';

import styled from 'styled-components'
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


const ResponsiveGridLayout = WidthProvider(Responsive);
const ResponsiveReactGridLayout = WidthProvider(Responsive);


const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
`

const WebcamPlayground = styled.div`
    display: flex;
    flex-wrap: wrap;
    
`

const CameraContainer = styled.div`
    position: relative;
    cursor: pointer;
    background: rgba(255,255,255,0.3);
    
    & video {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height: 100%;
        object-fit: cover;
    }
`


export default function DragAndDropPage () {


 
    return (
      <Layout>
        <SEO title="Drag and Drop" />
        <Container>
     
            <ResponsiveReactGridLayout
                className="layout"
                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
            >
                <CameraContainer key="a" data-grid={{x: 0, y: 0, w: 6, h: 2}}>
                    <Webcam width={'100%'} height={'100%'} mirrored/>
                </CameraContainer>
                <CameraContainer key="b" data-grid={{x: 7, y: 0, w: 6, h: 2}}>
                    <Webcam width={'100%'} height={'100%'} mirrored/>
                </CameraContainer>
                <CameraContainer key="c" data-grid={{x: 0, y: 3, w: 1, h: 2}}>
                    <Webcam width={'100%'} height={'100%'} mirrored/>
                </CameraContainer>
                <CameraContainer key="d" data-grid={{x: 6, y: 3, w: 1, h: 1}}>
                    <Webcam width={'100%'} height={'100%'} mirrored/>
                </CameraContainer>
                <CameraContainer key="e" data-grid={{x: 7, y: 3, w: 1, h: 1}}>
                    <Webcam width={'100%'} height={'100%'} mirrored/>
                </CameraContainer>
                <CameraContainer key="f" data-grid={{x: 8, y: 3, w: 1, h: 1}}>
                    <Webcam width={'100%'} height={'100%'} mirrored/>
                </CameraContainer>
                <CameraContainer key="g" data-grid={{x: 9, y: 3, w: 1, h: 1}}>
                    <Webcam width={'100%'} height={'100%'} mirrored/>
                </CameraContainer>
                <CameraContainer key="h" data-grid={{x: 10, y: 3, w: 1, h: 1}}>
                    <Webcam width={'100%'} height={'100%'}/>
                </CameraContainer>
                  <CameraContainer key="i" data-grid={{x: 11, y: 3, w: 1, h: 1}}>
                    <Webcam width={'100%'} height={'100%'} mirrored/>
                </CameraContainer>
            </ResponsiveReactGridLayout>
      </Container>
      </Layout>
    );
  }

